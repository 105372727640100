import React, { Fragment, Suspense, lazy } from 'react';
import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import Pace from './components/shared/Pace';
import { FirebaseProvider } from './firebase/FirebaseContext';
import { AuthProvider } from './auth/AuthContext';
import firebaseConfig from './firebaseConfig.json';
import SignInPage from './pages/SignInPage';

const LoggedInComponent = lazy(() => import('./pages/LoggedInMain'));

const config = {
  firebaseConfig: firebaseConfig,
  brand: 'sketch2Render',
  pathnames: {
    // "ActionPages": "/auth-action",
    // "ResetPassword": "/reset-password",
    SignIn: '/sign-in',
    // "SignUp": "/sign-up",
    // "UserDelete": "/user/delete",
    // "UserProfile": "/user",
    // "UserUpdateEmail": "/user/update-email",
    // "UserUpdateName": "/user/update-name",
    // "UserUpdatePassword": "/user/update-password"
  },
  authProviders: { google: true },
};

function App() {
  return (
    <FirebaseProvider config={config}>
      <AuthProvider>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyles />
              <Pace color={theme.palette.primary.light} />
              <Suspense fallback={<Fragment />}>
                <Routes>
                  <Route path="/*" element={<LoggedInComponent />} />
                  <Route path="/sign-in" element={<SignInPage />} />
                </Routes>
              </Suspense>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </AuthProvider>
    </FirebaseProvider>
  );
}

export default App;
